import React, { FC, useContext } from "react";
import { sc, SerializedItem, useSitecoreContext } from "~/foundation/Jss";
import {
	GlobalContacsAccordionRichText
} from "../generated-types";
import { Box, Text as ChakraText } from "@chakra-ui/react";
import { RichTextStylingSmall } from "~/foundation/Components/RichText/styles";
import { ColorThemeContext } from "~/foundation/Theme/ColorThemeContext";

type RichTextProps = {
	item: SerializedItem<GlobalContacsAccordionRichText>
}

export const RichText: FC<RichTextProps> = ({ item }) => {
	const { sitecoreContext } = useSitecoreContext();
	const [theme] = useContext(ColorThemeContext);

	return <>
		{(item.fields?.title?.value || sitecoreContext.pageEditing) &&
			<ChakraText as="h3" variant="footerHeading" textTransform="none" display="inline-block" mb="1">
				<sc.Text field={item.fields?.title} />
			</ChakraText>
		}
		{(item.fields?.text?.value || sitecoreContext.pageEditing) &&
			<Box { ...RichTextStylingSmall(theme, sitecoreContext.custom.settings.isRtl) }>
				<sc.RichText field={item.fields?.text}/>
			</Box>
		}
	</>
}