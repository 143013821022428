import React, { FC, useEffect, useRef, useState } from "react";
import { Ribbon } from "~/foundation/Components/Ribbon";
import { withErrorHandling } from "~/foundation/ErrorHandling";
import { useSitecoreContext } from "~/foundation/Jss";
import { generateHeaderId } from "../Chapter";
import { ChapterNavigationProps } from "../generated-types";
import { MotionBox } from "~/foundation/Framer/MotionBox";
import { Box, ListItem, List, Text as ChakraText, useToken, useMediaQuery } from "@chakra-ui/react";
import { ContentWrapper } from "~/foundation/Components/ContentWrapper";
import { usePageTheme } from "~/foundation/Theme/enhancers/usePageTheme";
import { PageThemeBase } from "~/foundation/Theme/generated-types";
import { useLocation, useNavigate } from "react-router-dom";
import { animations } from "~/foundation/Theme/styles/animations";
import { breakpoints } from "~/foundation/Theme/variables/breakpoints";

type ChapterHeaderPosition = {
	id: string,
	element: HTMLDivElement,
	startY: number,
	endY: number,
	goTo: (isFixed: boolean) => void;
}

const ChapterNavigation: FC<ChapterNavigationProps> = ({ rendering }) => {
	const [isFixed, setIsFixed] = useState(false);
	const [scrollingUp, setScrollingUp] = useState(false);
	const { sitecoreContext } = useSitecoreContext<PageThemeBase>();
	const isRtl = sitecoreContext.custom.settings.isRtl;
	const theme = usePageTheme(sitecoreContext);
	const [isLgBreakpointOrAbove] = useMediaQuery(`(min-width: ${breakpoints.lg})`, {
		ssr: true,
		fallback: true,
	});
	const [headerBgColor] = useToken('colors', [theme.colors.header.bg]);
	const [linkColor] = useToken('colors', [theme.colors.header.color]);
	const [linkColorActive] = useToken('colors', [theme.colors.header.activeColor]);
	const { hash } = useLocation();
	const allowScroll = useRef<boolean>(true);
	const activeChapter = useRef<ChapterHeaderPosition | null>(null);
	const chapterNav = useRef<HTMLDivElement | null>(null);
	const chapterHeaderPositions = useRef<ChapterHeaderPosition[]>([]);
	const navigate = useNavigate();
	const scrollPaddingTop = useRef<number>(0);

	if ((rendering.customData?.chapterHeaders || []).length === 0 && sitecoreContext.pageEditing) {
		return <div>No chapter headers on this page</div>
	}

	const updateChapterNav = (activeId: string | null) => {
		const chapterNavItems: HTMLAnchorElement[] = Array.from(document.querySelectorAll("section[data-chapter-nav] a"));
		const chapterNavList = document.querySelector<HTMLElement>("section[data-chapter-nav] ul");
		let contentWrapperWidth: number;

		//possible chapternav overflow
		if (chapterNavList?.parentElement?.parentElement) {
			const contentWrapper = chapterNavList?.parentElement?.parentElement;
			const contentWrapperStyles = getComputedStyle(contentWrapper);
			contentWrapperWidth = contentWrapper.clientWidth;
			contentWrapperWidth -=
				parseFloat(contentWrapperStyles.paddingInlineStart) +
				parseFloat(contentWrapperStyles.paddingInlineEnd);
		}

		chapterNavItems?.forEach((chapterNavItem) => {
			chapterNavItem.parentElement?.classList.remove("is-active");

			const [, id] = decodeURI(chapterNavItem.href).split("#");

			if (activeId === id) {
				chapterNavItem.parentElement?.classList.toggle("is-active");

				if (chapterNavItem.parentElement && chapterNavList && (contentWrapperWidth < chapterNavList.scrollWidth)){
					const scrollX: number = chapterNavItem.parentElement.offsetLeft;
					setTimeout(() => {
						chapterNavList.scrollTo({ top: 0, left: scrollX, behavior: 'smooth' })
					}, 600);
				}
			}
		});
		if (chapterNavList && chapterNavList.offsetWidth < chapterNavList.scrollWidth) {
			setTimeout(() => {
				allowScroll.current = true;
			}, 600);
		} else {
			allowScroll.current = true;
		}
	}

	const positionChapterNav = (chapterNav: HTMLElement, chapterNavY: number) => {
		const headerAdjustment = scrollingUp ? (isLgBreakpointOrAbove ? 60 : 80) : 0;
		setIsFixed((window.scrollY + headerAdjustment) > chapterNavY);
	}

	const onPageScroll = () => {
		const firstChapterStartY = chapterNav.current?.offsetTop || 0;
		if (window.scrollY < firstChapterStartY) {
			activeChapter.current = null;
			updateChapterNav(null);
			return;
		}


		const currentChapter = chapterHeaderPositions.current.find(x => window.scrollY > x.startY && window.scrollY < x.endY);

		if (allowScroll.current) {
			if (currentChapter && currentChapter.id !== activeChapter.current?.id) {
				activeChapter.current = currentChapter;
				updateChapterNav(currentChapter.id);
			}
		}
	}

	useEffect(() => {
		if (window.location.hash) {
			setTimeout(() => {
				window.scrollTo({ top: 0, behavior: "auto" });
			}, 1);
		}

		if (!chapterNav.current) {
			return
		}

		const chapterNavStartY = chapterNav.current.offsetTop;
		const threshold = 0;
		let lastScrollY = window.scrollY;
		let ticking = false;

		if (chapterNavStartY) {
			positionChapterNav(chapterNav.current, chapterNavStartY);
		}

		const updateScrollDir = () => {
			if (chapterNav.current) {
				const chapterNavStartY = chapterNav.current.offsetTop;

				if (chapterNav && chapterNavStartY) {
					positionChapterNav(chapterNav.current, chapterNavStartY);
				}
			}

			const scrollY = window.scrollY;

			if (Math.abs(scrollY - lastScrollY) < threshold) {
				ticking = false;
				return;
			}

			setScrollingUp(scrollY <= lastScrollY);
			lastScrollY = scrollY > 0 ? scrollY : 0;
			ticking = false;
		};

		const onScroll = () => {
			if (!ticking) {
				window.requestAnimationFrame(updateScrollDir);
				ticking = true;
			}
		};

		setTimeout(() => {
			if (hash) {
				goToChapter(hash.substring(1), true, true);
			}
		}, 1000);

		onPageScroll();
		window.addEventListener("scroll", onPageScroll);
		window.addEventListener("scroll", onScroll);

		return () => {
			window.removeEventListener("scroll", onPageScroll);
			window.removeEventListener("scroll", onScroll)
		};
	}, []);

	useEffect(() => {
		updateScrollPaddingTop(isLgBreakpointOrAbove, scrollingUp);
		updateChapterHeaderPositions();
	}, [scrollingUp, isLgBreakpointOrAbove]);

	function updateScrollPaddingTop(isLgBreakpointOrAbove: boolean, scrollingUp: boolean) {
		if (isLgBreakpointOrAbove) {
			scrollPaddingTop.current = scrollingUp ? 189 : 88;
		} else {
			scrollPaddingTop.current = scrollingUp ? 142 : 80;
		}
	}

	function updateChapterHeaderPositions() {
		chapterHeaderPositions.current = [];
		const chapterHeaders = document.querySelectorAll<HTMLDivElement>("section[data-chapter-header]");
		chapterHeaders.forEach((chapterHeader, index) => {
			chapterHeaderPositions.current.push({
				id: chapterHeader.id,
				element: chapterHeader,
				startY: chapterHeader.offsetTop - scrollPaddingTop.current,
				endY: (chapterHeaders[index + 1]?.offsetTop || document.querySelector<HTMLDivElement>(".page-footer")?.offsetTop || window.innerHeight) - scrollPaddingTop.current,
				goTo: (isFixed) => {
					const top = chapterHeader.offsetTop - scrollPaddingTop.current - (isFixed ? 0 : 152);
					window.scrollTo({ top, behavior: "smooth" })
				}
			});
		});
	}

	const goToChapter = (activeId: string, isFixed: boolean, isScrollingUp: boolean | null) => {
		allowScroll.current = false;

		const currentId = activeChapter.current?.id;
		const currentIdIndex = chapterHeaderPositions.current.findIndex(x => x.id === currentId);
		const nextIdIndex = chapterHeaderPositions.current.findIndex(x => x.id === activeId);

		const scrollingUp = isScrollingUp ?? currentIdIndex > nextIdIndex;
		updateScrollPaddingTop(isLgBreakpointOrAbove, scrollingUp);
		updateChapterHeaderPositions();

		const chapter = chapterHeaderPositions.current.find(x => x.id === activeId);

		if (chapter) {
			activeChapter.current = chapter;
			chapter.goTo(isFixed);
			navigate({
				hash: activeId
			})
		}

		setTimeout(() => {
			updateChapterNav(activeId);
		}, 500);
	}

	const motionVariants = {
		relative: {
			y: "0px",
			transition: {
				duration: 0,
			}
		},
		navOpen: {
			y: isLgBreakpointOrAbove ? "71px" : "62px",
		},
		navClosed: {
			y: "0",
		},
	}

	const transition = {
		default: {
			ease: [.35, 1, .45, 1],
			duration: 1,
		}
	}

	return (
		<Ribbon ref={chapterNav} data-chapter-nav position="relative">
			<MotionBox
				animate={isFixed ? (scrollingUp ? "navOpen" : "navClosed") : "relative"}
				variants={motionVariants}
				initial="relative"
				position={isFixed ? "fixed" : "relative"}
				top="0"
				w="100%"
				overflowX="hidden"
				zIndex={isFixed ? "2" : "0"}
				transition={transition}
				bg={headerBgColor}
				color={theme.colors.header.color}
				mb="8"
				pb={["4", null, "8"]}
			>
				<ContentWrapper
					position="relative"
					py={["0", null]}
					pt={["2", null, "0"]}
					_before={{
						content: "''",
						display: "block",
						position: "absolute",
						bottom: "0",
						right: isRtl ? "auto" : ['calc(100% - var(--chakra-sizes-pageMarginSM))', null, "calc(100% - 64px)", null, 'calc(100% - 128px)'],
						left: isRtl ? ['calc(100% - var(--chakra-sizes-pageMarginSM))', null, "calc(100% - 64px)", null, 'calc(100% - 128px)'] : "auto",
						w: "100%",
						h: "1px",
						bg: "primary.neutralGrey",
					}}
				>
					<Box
						as="nav"
						position="relative"
						display={["initial", null, "inline-block"]}
						_after={{
							content: "''",
							display: "block",
							position: "absolute",
							bottom: "0",
							left: isRtl ? "auto" : "100%",
							right: isRtl ? "100%" : "auto",
							w: "100vw",
							h: "1px",
							bg: "primary.neutralGrey",
						}}>
						<List
							display="flex"
							justifyContent="flex-start"
							overflow="auto"
							gap="16"
							sx={{
								"&::-webkit-scrollbar": {
									display: "none"
								},
								msOverflowStyle: "none",
								scrollbarWidth: "none",
							}}
						>
							{rendering.customData?.chapterHeaders.filter(chapter => chapter.title.value).map((chapter, index) => (
								<ListItem
									key={index}
									position="relative"
									py={["4", null, "8"]}
									minW="fit-content"
									sx={{
										"a": {
											display: "inline-flex",
											"&:focus": {
												background: "none",
												outlineOffset: "10px",
											},
										},
										"&:after": {
											content: "''",
											display: "block",
											position: "absolute",
											bottom: "0",
											zIndex: "1",
											w: "calc( 100% + 4rem )",
											h: "1px",
											bg: "primary.neutralGrey",
											color: linkColor,
											transitionProperty: "background",
											transitionDuration: ".4s",
										},
										"&.is-active": {
											color: linkColorActive,
											"&:after": {
												bg: "transparent"
											}
										}
									}}
								>
									<a
										href={`#${generateHeaderId(chapter.title.value)}`}
										onClick={(e) => {
											e.preventDefault();
											goToChapter(generateHeaderId(chapter.title.value), isFixed, null);
										}}>
										<ChakraText variant="footerBody" sx={isLgBreakpointOrAbove ? {
											...animations(isRtl, "currentColor", ".35s").underline
										} : {}}>
											{chapter.chapterNavigationTitle.value ? chapter.chapterNavigationTitle.value : chapter.title.value}
										</ChakraText>
									</a>
								</ListItem>
							))}
						</List>
					</Box>
				</ContentWrapper>
			</MotionBox>
		</Ribbon>
	);
};

export default withErrorHandling()(ChapterNavigation);