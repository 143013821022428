import React, { FC } from "react";
import { HeroDisclaimerTextBase, HeroProps } from "../generated-types";
import { Text as ChakraText, Box, Flex, Heading } from "@chakra-ui/react";
import { imageSizes } from "~/foundation/Theme";
import { ContentWrapper } from "~/foundation/Components/ContentWrapper";
import { sc, useSitecoreContext } from "~/foundation/Jss";
import { Ribbon } from "~/foundation/Components/Ribbon";
import { heroStyles } from "./styles";
import { Video } from "~/foundation/Components/Video";
import { MotionBox } from "~/foundation/Framer/MotionBox";
import { SiteNavigation } from "~/foundation/Components/SiteNavigation";
import { BreakingNews } from "~/foundation/Components/BreakingNews";
// We need to import the BreadcrumbNavigation component directly from the Navigation feature.
// eslint-disable-next-line helix-structure/restricted-imports
import { BreadcrumbNavigation } from "~/feature/Navigation";

const Hero: FC<HeroProps> = ({ rendering, fields }) => {
	const { sitecoreContext } = useSitecoreContext<HeroDisclaimerTextBase>();
	const isRtl = sitecoreContext.custom.settings.isRtl;

	const trumpet = {
		initial: {
			x: 2,
			y: 41
		},
		animate: {
			x: 0,
			y: 0,
		},
	}

	const headline = {
		initial: {
			x: 5,
			y: 150
		},
		animate: {
			x: 0,
			y: 0,
		},
	}

	const link = {
		initial: {
			y: 64
		},
		animate: {
			y: 0,
		},
	}

	const transition = {
		text: {
			ease: "easeInOut",
			duration: .75,
			delay: .1
		}
	}

	return (
		<Ribbon {...heroStyles(isRtl)}>
			<SiteNavigation sitecoreContext={sitecoreContext} />
			<BreakingNews sitecoreContext={sitecoreContext} />
			<Box className="hero__wrapper">
				<Box className="hero__background">
					{fields?.backgroundVideo?.value?.src ?
						<Video
							src={fields.backgroundVideo.value.src}
							autoPlay
							muted
							loop
							playsInline
							style={{
								position: "absolute",
								objectFit: "cover",
								objectPosition: "center center",
								minWidth: "100%",
								minHeight: "100%"
							}}/>
						:
						<>
							{(fields?.backgroundImage?.value?.src || sitecoreContext.pageEditing) &&
								<sc.AdvanceImage
									fields={fields}
									itemId={rendering.dataSource}
									fieldName="backgroundImage"
									srcSet={[imageSizes.m, imageSizes.l, imageSizes.xl]}
									sizes="100vw"
									lazy={false}
								/>
							}
						</>
					}
				</Box>
				{sitecoreContext?.route?.templateName === "Campaign Page" && (
					<Box
						className="breadcrumb--on-top"
						position="absolute"
						width="100%"
						top={["var(--chakra-sizes-headerHeightBase)", null, null, "var(--chakra-sizes-headerHeightXL)"]}
					>
						<BreadcrumbNavigation rendering={rendering}/>
					</Box>
				)}
				<ContentWrapper
					className="hero__content"
					pt={["20", null, "7.75rem", null, "7.75rem"]}
					pb={["4", null, "8", null, "16"]}
					h="100%">
					<Flex
						position="relative"
						direction="column" h="100%"
						justifyContent="center"
						maxW="790px"
					>
						{(fields?.trumpet?.value || sitecoreContext.pageEditing) &&
							<Box className="hero__trumpet">
								{
									fields?.primaryLink?.value
										?
										<MotionBox
											variants={trumpet}
											initial="initial"
											animate="animate"
											transition={transition.text}>
											<sc.Link field={fields.primaryLink} showLinkTextWithChildrenPresent={false} editable={false}>
												<ChakraText variant="overline" lineHeight={isRtl ? ['1.75rem', null, null, "2rem"] : ['1rem', null, null, "1.125rem"]}>
													<sc.Text field={fields.trumpet} />
												</ChakraText>
											</sc.Link>
										</MotionBox>
										:
										<MotionBox
											variants={trumpet}
											initial="initial"
											animate="animate"
											transition={transition.text}>
											<ChakraText variant="overline" lineHeight={isRtl ? ['1.75rem', null, null, "2rem"] : ['1rem', null, null, "1.125rem"]}>
												<sc.Text field={fields?.trumpet} />
											</ChakraText>
										</MotionBox>
								}
							</Box>
						}
						{(fields?.headline?.value || sitecoreContext.pageEditing) &&
							<span className="hero__headline">
								{
									fields?.primaryLink?.value
										?
										<sc.Link
											field={fields.primaryLink}
											showLinkTextWithChildrenPresent={false}
											editable={false}
											// dataLayer={() => ({
											// 	event: "GAevent",
											// 	event_type: "click",
											// 	event_name: "hero",
											// 	text: fields?.headline?.value,
											// 	type: "primary",
											// 	url: fields?.featuredPrimaryLink?.value.href
											// })}
										>
											<MotionBox
												variants={headline}
												initial="initial"
												animate="animate"
												transition={transition.text}
											>
												<Heading
													as="h1"
													size={["xxl", null, "xl"]}
													pb="2"
													mb={["4", null, "2"]}
													noOfLines={[3, null, 4]}
													wordBreak="normal">
													<sc.Text field={fields.headline} />
												</Heading>
											</MotionBox>
										</sc.Link>
										:
										<MotionBox
											variants={headline}
											initial="initial"
											animate="animate"
											transition={transition.text}
										>
											<Heading as="h2" size="xl" mb={["6", null, "4"]} noOfLines={[3, null, 4]} wordBreak="normal">
												<sc.Text field={fields?.headline} />
											</Heading>
										</MotionBox>
								}
							</span>
						}
						{(fields?.primaryLink || fields?.secondaryLink) &&
							<Flex
								wrap="wrap"
								direction={["column", null, "row"]}
								className="hero__links">
								<>
									{fields?.primaryLink?.value.href &&
										<MotionBox
											me={[null, null, "16"]}
											mt={[null, null, "4"]}
											className="hero__link"
											variants={link}
											initial="initial"
											animate="animate"
											transition={transition.text}
										>
											<sc.Link
												field={fields?.primaryLink}
												variant="primary"
												size={["md", null, "lg"]}
												color="white"
												// dataLayer={() => ({
												// 	event: "GAevent",
												// 	event_type: "click",
												// 	event_name: "hero",
												// 	text: fields?.headline?.value,
												// 	type: "primary",
												// 	url: fields?.featuredPrimaryLink?.value.href
												// })}
											/>
										</MotionBox>
									}
									{fields?.secondaryLink?.value.href &&
										<MotionBox
											mt={["6", null, "4"]}
											display="flex"
											className="hero__link"
											variants={link}
											initial="initial"
											animate="animate"
											transition={transition.text}>
											<sc.Link
												field={fields?.secondaryLink}
												variant="secondary"
												size={["md", null, "lg"]}
												color="white"
												// dataLayer={() => ({
												// 	event: "GAevent",
												// 	event_type: "click",
												// 	event_name: "hero",
												// 	text: fields?.headline?.value,
												// 	type: "secondary",
												// 	url: fields?.featuredSecondaryLink?.value.href
												// })}
											/>
										</MotionBox>
									}
								</>
							</Flex>
						}
					</Flex>
				</ContentWrapper>
			</Box>
			{(sitecoreContext.route?.fields?.disclaimerText?.value || sitecoreContext.pageEditing) &&
				<ContentWrapper py={4}>
					<Box bg="primary.white" color="primary.black" textAlign="center">
						<ChakraText variant="default">
							<sc.Text field={sitecoreContext.route?.fields?.disclaimerText} />
						</ChakraText>
					</Box>
				</ContentWrapper>
			}
		</Ribbon>
	);
};

export default Hero;

