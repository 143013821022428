import React, { FC } from "react";
import { SerializedItem, sc } from "~/foundation/Jss";
import { GlobalContacsAccordionLink, GlobalContacsAccordionLinkList } from "../generated-types";
import { Box } from "@chakra-ui/react";

type LinkListProps = {
	item: SerializedItem<GlobalContacsAccordionLinkList, GlobalContacsAccordionLink>
}

export const LinkList: FC<LinkListProps> = ({ item }) => (
	<>
		{item.children.map((item, index) => (
			<Box key={index} mt="1">
				<sc.Link field={item.fields?.link} variant="secondary" size="md" color="primary.aramcoLinkBlue" />
			</Box>
		))}
	</>
)