import React, { FC } from "react";
import { SubpageNavigationProps } from "../generated-types";
import { Flex, Text as ChakraText } from "@chakra-ui/react";
import { Ribbon } from "~/foundation/Components/Ribbon";
import { Link as RouterLink } from "react-router-dom";

const SubpageNavigation: FC<SubpageNavigationProps> = ({ rendering }) => {

	if (!rendering.customData?.menuItems) {
		return <></>
	}

	return (
		<Ribbon
			position="sticky"
			top="0"
			zIndex="3">
			<Flex
				overflowX="auto"
				sx={{
					"> a": {
						flex: "1 1 auto",
					}
				}}>
				{rendering.customData?.menuItems.map((item, index) => (
					<RouterLink key={index} to={item.url}>
						<ChakraText
							size="xxs"
							lineHeight="1rem"
							bgColor={item.isActive ? "primary.nightGrey.600" : "primary.white"}
							color={item.isActive ? "primary.white" : "inherit"}
							py="5"
							px="8"
							display="flex"
							height="100%"
							minW="11.25rem"
							alignItems="center"
							borderStyle="solid"
							borderColor={item.isActive ? "primary.nightGrey.600" : "primary.lightGrey"}
							borderTopWidth="1px"
							borderBottomWidth="1px"
							borderLeftWidth="1px"
							transitionDuration=".8s"
							transitionTimingFunction="aramcoCubic"
							transitionProperty="border-color, color, background-color"
							_hover={{
								borderColor: "primary.nightGrey.600",
								color: "primary.white",
								bgColor: "primary.nightGrey.600"
							}}>
							{item.title}
						</ChakraText>
					</RouterLink>
				)
				)}
			</Flex>
		</Ribbon>
	);
};

export default SubpageNavigation;