import React, { FC } from "react";
import { sc, useSitecoreContext } from "~/foundation/Jss";
import { ChapterProps } from "../generated-types";
import { Ribbon } from "~/foundation/Components/Ribbon";
import { ContentWrapper } from "~/foundation/Components/ContentWrapper";
import { Box, Flex, Heading } from "@chakra-ui/react";
import { themable } from "~/foundation/Theme";

export function generateHeaderId(header?: string) {
	if (!header) {
		return "";
	}

	return header
		.split(" ").join("-")
		.split(".").join("")
		.toLowerCase();
}

const Chapter: FC<ChapterProps> = ({ fields, params }) => {
	const { sitecoreContext } = useSitecoreContext();
	const removeSideSpacing = params.removeSideSpacing === "1";

	if (!fields?.title?.value && !sitecoreContext.pageEditing) {
		return <></>;
	}
	
	const headerId = generateHeaderId(fields?.title?.value);

	if (fields?.hide?.value && !sitecoreContext.pageEditing) {
		return <section id={headerId} data-chapter-header data-chapter-id={headerId}/>
	}

	return (
		<Ribbon id={headerId} data-chapter-header data-chapter-id={headerId}>
			<ContentWrapper py={["0", null]} pt={["var(--chakra-sizes-modulePY__SM)", null, "var(--chakra-sizes-modulePY__MD)", null, "var(--chakra-sizes-modulePY__XL)"]}>
				<Flex justifyContent={["none", null, "center"]}>
					<Box
						flexBasis={["100%", null, removeSideSpacing ? "100%" : "60%"]}
						maxW={["100%", null, removeSideSpacing ? "100%" : "60%"]}
					>
						<Heading
							as="h2"
							fontFamily="body"
							fontSize={["xl", null, null, "3xl"]}
							fontWeight="300"
							letterSpacing={['normal', null, null, 'wide']}
							lineHeight={['2.25rem', null, null, '1.35']}>
							<sc.Text field={fields?.title} />
						</Heading>
					</Box>
				</Flex>
			</ContentWrapper>
		</Ribbon>
	);
};

export default themable()(Chapter);