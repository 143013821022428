export const heroStyles = (isRtl: boolean) => ({
	'sx': {
		"@keyframes revealBelow": {
			from: {
				height: "100dvh"
			},
			to: {
				height: "95dvh",
			}
		},
		'.hero__wrapper': {
			position: "relative",
			color: "white",
			h: "100dvh",
			animationName: "revealBelow",
			animationDuration: "1s",
			animationDelay: "2s",
			animationFillMode: "forwards",
			animationTimingFunction: "cubic-bezier(.23,1.76,.47,1.09)",
			overflow: "hidden",
		},
		'.hero__background': {
			position: "absolute",
			w: "100%",
			h: "100%",
			"img": {
				position: "absolute",
				objectFit: "cover",
				objectPosition: "center center",
				minWidth: "100%",
				minHeight: "100%",
				transform: "scale(1.03)",
				transformOrigin: "top right",
				transition: "transform .8s ease-out",
			},
			"&:after": {
				content: `''`,
				position: 'absolute',
				top: '0',
				right: '0',
				bottom: '0',
				left: '0',
				background: ["linear-gradient(69.09deg, rgba(0, 0, 0, 0.76) 31.16%, rgba(0, 0, 0, 0) 90.51%), linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 27.65%)", null, null, "linear-gradient(81.38deg, rgba(0, 0, 0, 0.8075) 34.59%, rgba(0, 0, 0, 0) 71.8%), linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 27.65%)", null, "linear-gradient(72.77deg, rgba(0, 0, 0, 0.855) 26.39%, rgba(0, 0, 0, 0) 83.78%, rgba(0, 0, 0, 0.27) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 27.65%)"],
				transform: isRtl ? "scaleX(-1)" : "",
			}
		},
		'.hero__content': {
			'.hero__trumpet, .hero__headline, .hero__links': {
				display: "inline-flex",
				overflow: "hidden"
			},
			'.hero__trumpet': {
				mb: "4"
			},
		},
	}
})