import React, { FC, useContext } from "react";
import { GlobalContacsAccordionItemBase, GlobalContactsNavigationProps } from "../generated-types";
import { ContentWrapper } from "~/foundation/Components/ContentWrapper";
import { Ribbon } from "~/foundation/Components/Ribbon";
import { Accordion as ChakraAccordion, AccordionButton, AccordionItem, AccordionPanel, Box, Flex, Text as ChakraText } from "@chakra-ui/react";
import { sc, SerializedItem, useSitecoreContext } from "~/foundation/Jss";
import { Icon } from "~/foundation/Components/Icon";
import { ColorThemeContext } from "~/foundation/Theme/ColorThemeContext";
import { constants } from "../constants";
import { LinkList } from "./LinkList";
import { RichText } from "./RichText";
import { SocialMediaList } from "./SocialMediaList";
import { Link } from "~/foundation/Components/Link";

const GlobalContactsNavigation: FC<GlobalContactsNavigationProps> = ({ rendering }) => {
	const { sitecoreContext } = useSitecoreContext();
	const [theme] = useContext(ColorThemeContext);
	const { colors } = theme;
	const defaultExpandedIndexes = [...Array(rendering.children?.length)].map((_, index) => index);
	const languageLinks = rendering.customData?.items || [];

	return (
		<Ribbon>
			<ChakraAccordion
				flexBasis={["100%", null, "60%"]}
				allowMultiple={true}
				defaultIndex={sitecoreContext.pageEditing ? defaultExpandedIndexes : [0]}
				data-tracking-type="visibility"
				data-tracking-id="global_contacts_accordion"
				data-tracking-data={JSON.stringify({ type: "global contacts accordion" })}
			>
				<>
					{rendering.children?.map((child: SerializedItem<GlobalContacsAccordionItemBase>, index) => {
						const languageLink = languageLinks.find(x => x.id === child.id);

						return (
							<AccordionItem
								key={index}
								borderTop= "1px solid"
								borderColor="primary.lightGrey"
							>
								{({ isExpanded }) => (
									<Box bg={isExpanded ? "primary.white" : "primary.brightGrey"}>
										<h2>
											<ContentWrapper py="0">
												<AccordionButton
													pt={["8", null, "10"]}
													pb={isExpanded ? ["4"] : ["8", null, "10"]}
													px="0"
													transitionProperty="padding,color"
													transitionDuration=".3s"
													transitionTimingFunction="ease-in-out"
													color="inherit"
													_hover={{ color: colors.ribbon.linkColor }}>
													<ChakraText as="span" size="xs" flex="1" textAlign="start">
														<sc.Text field={child.fields?.title} />
														{languageLinks.length > 0 &&
															<Box ps={[0, null, 12]} display={["block", null, "inline-block"]}>
																{languageLink?.data.filter(x => x.url).map((languageLinkItem, index) =>
																	<>
																		<Link
																			key={`language_link_${index}`}
																			to={languageLinkItem.url!}
																			isExternal
																			target="_blank"
																			rel="noopener"
																			size="sm"
																			color={index === 0 ? "inherit" : theme.colors.ribbon.linkColor}
																		>
																			{languageLinkItem.title}
																		</Link>
																		{index < languageLink.data?.length - 1 &&
																			<ChakraText as="span" fontSize="xs" px="2">|</ChakraText>
																		}
																	</>
																)}
															</Box>
														}
													</ChakraText>
													<Icon
														variant={isExpanded ? "Up" : "Down"}
														w="icons.lg"
														marginInlineEnd="0"
														color={colors.ribbon.bg === "transparent" ? "primary.aramcoLinkBlue" : "inherit"}
														my="2"
														ms={["4", null, "12"]}
														me={["0", null, "1.125rem"]}
													/>
												</AccordionButton>
											</ContentWrapper>
										</h2>
										<AccordionPanel pb={["8", null, "12"]} pt="0" px="0" bg="primary.white">
											<ContentWrapper py="0">
												<Flex flexDirection="row" flexWrap="wrap" gap={["10"]} me={["0", null, "-12"]}>
													{child.children.map((child, index) => {
														return <Box key={index} flexBasis={["100%", "calc(50% - 1.5rem)", null, "calc(33.333% - 3rem)"]}>
															{child.templateId === constants.globalContactsNavigation.templateIds.linkList &&
																<LinkList item={child} />
															}
															{child.templateId === constants.globalContactsNavigation.templateIds.richText &&
																<RichText item={child} />
															}
															{child.templateId === constants.globalContactsNavigation.templateIds.socialMediaList &&
																<SocialMediaList item={child} />
															}
														</Box>
													})}
												</Flex>
											</ContentWrapper>
										</AccordionPanel>
									</Box>
								)}
							</AccordionItem>
						);
					})}
				</>
			</ChakraAccordion>
		</Ribbon>
	);
};

export default GlobalContactsNavigation;