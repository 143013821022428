import React, { FC } from "react";
import { ItemReference, SerializedItem } from "~/foundation/Jss";
import { GlobalContacsAccordionSocialMediaList } from "../generated-types";
import { Box, Flex, Grid } from "@chakra-ui/react";
import { Icon, IconType } from "~/foundation/Components/Icon";
import { Link } from "~/foundation/Components/Link";
import { SocialMedia } from "~/foundation/SitecoreContent/generated-types";

type SocialMediaListProps = {
	item: SerializedItem<GlobalContacsAccordionSocialMediaList>;
}

export const SocialMediaList: FC<SocialMediaListProps> = ({ item }) => {
	return <Box maxW="200px">
		<Grid templateColumns="repeat(3, 1fr)" gap={8}>
			{item.fields?.socialMediaLinks?.map((item: ItemReference<SocialMedia>, index) => {
				let iconType: IconType;
				switch (item.fields.socialMediaName?.value) {
					case "Facebook":
						iconType = "Facebook";
						break;
					case "Instagram":
						iconType = "Instagram";
						break;
					case "LinkedIn":
						iconType = "LinkedIn";
						break;
					case "Twitter":
						iconType = "TwitterX";
						break;
					case "Youtube":
						iconType = "Youtube";
						break;
					default:
						return "";
				}
				return (
					<Link
						key={index}
						color="primary.aramcoLinkBlue"
						to={item.fields.socialMediaLink?.value.href!}
						ariaLabel={item.fields.socialMediaName?.value}
						display="inline-block"
						isExternal
						target="_blank"
						rel="noopener">
						<Flex h="45px"
							w="45px"
							justifyContent="center"
							alignItems="center"
							borderRadius="50%"
							borderColor="currentColor"
							borderWidth=".125rem">
							<Icon
								variant={iconType}
								className="icon"
							/>
						</Flex>
					</Link>
				)
			})
			}
		</Grid>
	</Box>
}